import './Home.scss'
import Intro from '../../Components/Intro/Intro'
import About from '../About/About'
import ContactMe from '../ContactMe/ContactMe'

const Home = () => {
  return (
    <div className='home'>
      <Intro />
      <About />
      <ContactMe />
    </div>
  )
}

export default Home
