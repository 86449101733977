import './StoryCard.scss'
import { useState } from 'react'

const StoryCard = ({ title, body }) => {
  const [displayed, setDisplayed] = useState(true)

  const toggleDisplay = () => {
    setDisplayed(!displayed)
  }

  return (
    <div className='story-card'>
      <div className='story-card--header' onClick={toggleDisplay}>
        <h3>{title}</h3>
        {
          displayed ? <h2>-</h2> : <h2>+</h2>
        }
      </div>
      {
        displayed ? <div className='story-card--body'>
          <h3>{body}</h3>
        </div> : <></>
      }
    </div>
  )
}

export default StoryCard
