import React, { useState, useEffect } from 'react';
import './Header.scss';
import hamburgerIcon from '../../styles/images/humburger-icon.svg'

const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleButtonClick = () => {
    setButtonClicked(!buttonClicked)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleButtonClick()

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='header'>
      <h5 className='header--title'>ANTHONY NAJM</h5>
      {windowWidth > 768 ? (
        <div className='header--navbar-links'>
          <a href="#home"><p>Home</p></a>
          <a href="#my-journey"><p>My Journey</p></a>
          <a href="#short-stories"><p>Short Stories</p></a>
          <a href="#contact-me"><p>Contact Me</p></a>
        </div>
      ) : (
        <div className='header--navbar-dropdown'>
          {buttonClicked ? (
              <img src={hamburgerIcon} alt="hamburger-icon" onClick={handleButtonClick} className='header--navbar-dropdown__image'/>
            ) : (
              <>
                <p onClick={handleButtonClick} className='header--navbar-dropdown__image'>X</p>
                <div className='header--navbar-dropdown__links'>
                  <a href="#home" onClick={handleButtonClick}><p>Home</p></a>
                  <a href="#my-journey" onClick={handleButtonClick}><p>My Journey</p></a>
                  <a href="#short-stories" onClick={handleButtonClick}><p>Short Stories</p></a>
                  <a href="#contact-me" onClick={handleButtonClick}><p>Contact Me</p></a>
                </div>
              </>
            )
          }
        </div>
      )}
    </div>
  );
};

export default Header;
