import './ContactMe.scss'
import ShortInput from '../../Components/ShortInput/ShortInput'
import ButtonPurple from '../../Components/ButtonPurple/ButtonPurple'
import FreeTypeInput from '../../Components/FreeTypeInput/FreeTypeInput'

const ContactMe = () => {
  return (
    <div className='contact'>
      <div>
        <h2 className='contact--header' id='contact-me'>Contact Me</h2>
      </div>
      <div className='contact-me'>
        <div className='form'>
            <form action="https://public.herotofu.com/v1/ebb6a700-bd30-11ee-8fa9-872d80d8eac1" method="post" accept-charset="UTF-8">
              <ShortInput
                  label={"Name"}
                  inputType={"text"}
                  name={"name"}
              />
              <ShortInput
                  label={"Email"}
                  inputType={"email"}
                  name={"email"}
              />
              <FreeTypeInput
                  label={"Message"}
                  name={"message"}
              />
                <div className='button-section'>
                  <div className='buttons'>
                    <ButtonPurple buttonName={"Submit"} type={"submit"} />
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>
  )
}

export default ContactMe
