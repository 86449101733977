import './Intro.scss'
import smoke from '../../styles/images/smoke.gif'
import book from '../../styles/images/book.png'
import Trailer from '../../styles/videos/book-trailer.MP4'

import { useState } from 'react'

const Intro = () => {
  const [isDisplayed, setIsDisplayed] = useState(false)

  return (
    <div className='intro' id='home'>
      <div className='intro--info'>
        <div className='intro--info__headers'>
          <h2>The end is near</h2>
          <h4>Hold your breath</h4>
        </div>
        <div className='intro--design-div'></div>
      </div>
      <div className='intro--content'>
        <img src={smoke} alt="" className='intro--content__smoke'/>
        <img src={book} alt="Anthony Najm's book" className='intro--content__book' onClick={() => setIsDisplayed(true)}/>
      </div>
      {isDisplayed ?
      <div className='intro--video'>
        <video controls>
          <source src={Trailer} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="intro--video__button">
          <button className="intro--video__button_close" onClick={() => setIsDisplayed(false)}>X</button>
        </div>
      </div> : <></>
      }
    </div>
  )
}

export default Intro
