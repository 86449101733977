import './ButtonPurple.scss'

const ButtonPurple = ({ handleButtonClick, buttonName }) => {
  return (
    <div className='button-purple'>
      <button
        className='button'
        onClick={handleButtonClick}
      >
        {buttonName}
      </button>
    </div>
  )
}

export default ButtonPurple
