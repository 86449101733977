import './AboutMe.scss'
import Anthony from '../../styles/images/anthony.png'

const AboutMe = () => {
  return (
    <>
    <h2 className='about-me--header' id='my-journey'>My Journey</h2>
      <div className='about-me'>
        <div className='about-me--image-section'>
          <img src={Anthony} alt="Anthony image" className='about-me--image-section__image'/>
        </div>
        <div className='about-me--paragraph-section'>
          <p>
            I'm a Passionate writer with a bachelor's degree in Psychology from the Lebanese American University(LAU). I started writing when I was 10 years old. I have received multiple writing awards from school. In 2016, I received a philosophy award at Oxford Royal Academy during summer camp. In September 2017, my short story "Agartha" won the Italian jury of Energheia over, and I earned a trip to Matera, where "the youngest contestant's literary talent was the winner." My first novel, "The Lucky Carousel: Armageddon" is an intense mystery/thriller that I hope readers will enjoy.
          </p>
        </div>
      </div>
    </>
  )
}

export default AboutMe
