import './VideoLink.scss'
import React from 'react'
import Trailer from '../../styles/videos/book-trailer.MP4'

import { useState } from 'react'

const VideoLink = () => {
  const [isDisplayed, setIsDisplayed] = useState(false)

  return (
    <div className='video-link'>
      <p className='video-link__text' onClick={() => setIsDisplayed(true)}>
        CLICK HERE FOR TRAILER!
      </p>
      {isDisplayed ?
      <div className='video-link--video'>
        <video controls>
          <source src={Trailer} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-link--video__button">
          <button className="video-link--video__button_close" onClick={() => setIsDisplayed(false)}>X</button>
        </div>
      </div> : <></>}
    </div>
  )
}

export default VideoLink
