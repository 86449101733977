import StoryCard from "../StoryCard/StoryCard";
import "./ShortStories.scss";

const ShortStories = () => {
  return (
    <>
      <div className="short-stories--header" id='short-stories'>
        <h2 className="short-stories--header__title">Short Stories</h2>
      </div>
      <div className="short-stories">
        <div className="short-stories--left">
          <StoryCard
            title={"Queen of the White Forest"}
            body={
              "Deep inside a white forest, a woman and her wolf lived in a home below an oak tree. The house was built around it that provided shelter and comfort. The bed was stuffed with piles of leaves and the pillow incredible feathers, and there were many logs of wood to build a fire when it gets too cold to bare in the winter. She didn’t complain, didn’t curse, didn’t dare harm any living thing. The lady and her loyal companion never aged on a high mountain that was so close to the sky. She wore a white wedding dress, a graceful presence and eternally beautiful, her purity everlasting from the light of the moon. Her fragrance was that of a lily flower, fresh and sweet, and her hair was brown like Pinecones. Her eyes were ocean blue, and her face was free of blemishes. She was alone with her loyal wolf friend, looking to find a way out of her timeless misery. In her dreams, she was free and in the company of a handsome knight that suffers the same fate as her. But thus far, they were only hopeful dreams."
            }
          />
          <StoryCard
            title={"The Lightkeeper"}
            body={
              "In a galaxy far away, a guardian of light known as ‘the Skywalker’ has turned on his oath of honor and the promise of his lightsaber to protect the galaxy against evil. The Sirius Space Council ruled him a criminal, and sent their star warrior to capture and detain him. Now, Mr. Skywalker is being interrogated by a war veteran and advisor in an isolated ship that will be his home for the next five decades. “Mr. Skywalker, my name is Andrew Dames, I am here to make sense of your wrongdoings, and possibly reduce your sentence,” the council’s hellhound was a confident man with years of experience.  “Please reveal your hands and press them on the table for 15 seconds.” ‘The Skywalker’ lifted his hands from under the table and satisfied Andrew Dames’ first order. The hellhound nodded in approval, quickly noticing that his left middle finger shook. “We’ve found that detainees who coordinate from the beginning are more likely to communicate effectively when the questions start rolling in. Those who don’t participate usually come up as shy, or ego centric with an overexaggerated sense of pride. None of the criminals in the past were like you, so maybe you’ll enlighten me on your perceptions; I’m sure the council would be pleased.”"
            }
          />
        </div>
        <div className="short-stories--right">
          <StoryCard
            title={"The Cure"}
            body={
              "October 7, they’ve been repeating that day for 273 days now. Dr. Alex Adolf was a middle-aged scientist, physicist and chemist testing the loyalty and genius of his newest intern assistant, Beatrice Cureau. His wife Laurel was plagued with cancer, and according to his death watch he attached on her wrist, she will die at 7:01:36 p.m. To try to save her, he forced a time loop on himself and his assistant and wife so that a cure may be found in his lab in the basement. There was no clock in the lab because it would be a distraction. Nothing distracting was there, not even a watch. Alex counted the time in his head, and each day he counted accurately. There’s a big combustible soda bottle on the table along with two boiling tubes. Beatrice works her magic every day after 6:30 a.m. when they have breakfast, under the strict supervision of her superior, Dr Alex Adolf. Starting from the second week into October, she realized that he gives her a cold and mean stare just about every half-hour as she clicks her left forefinger 3 times on the table. It bothers him—bugs him to the core! But he never suspected anything. Alex was the chief, in command and in charge like his father before him who had a riotous determination that lead him to melting his hands when he finally finished his life’s work: a time machine with no consequences on the fabric of the cosmos, or in other words that doesn’t alter the minor details when he chooses to go back to the same time after a jump."
            }
          />
          <StoryCard
            title={"The Tail of Loss"}
            body={
              "“What do you think is on the other end?” Asked Kent impatiently, his eyes orbiting at the sight of a wide, deep dark hole that just yesterday appeared in his backyard. His brother George was taken aback by this spontaneous mystery, but he didn’t share his brother’s enthusiasm to actually find out where it leads. To him, it was trivial; it was a hole in the ground, however unusual in its sudden appearance and magnitude. “More dirt probably. Or, lava…” (that was a joke) “No way it’s that deep!” “You know you’re not going to go down there, right? Don’t be crazy.” George has always— after his brother’s former spouse died from alcohol poisoning, about three years ago— lectured Rony on the malevolence of being superstitious and libertine. He uses an infamous quote regularly by Carl Jung; “Who looks outside, dreams; who looks inside, awakes.” George had a degree in Psychology, but his brother was negligent to his advice. “How dare you? After everything… You have to let me be!” He controlled his aggression with his finger, sternly pointing at his brother, before pointing to the hole and saying, “I’m going down there.” Where Kent saw an adventure, George saw a dirty hole in the ground."
            }
          />
        </div>
      </div>
      <div className="links">
        <a
          href="https://www.amazon.com/dp/B0DG75LYC7?language=en_US&ref=cm_sw_r_mwn_dp_SADUHANHP2SE837QVE8T_1&ref_=cm_sw_r_mwn_dp_SADUHANHP2SE837QVE8T_1&social_share=cm_sw_r_mwn_dp_SADUHANHP2SE837QVE8T_1&starsLeft=1&skipTwisterOG=1&fbclid=PAZXh0bgNhZW0CMTEAAabScBpNwOVt903TocXugx4iwVGfzi1XLQ9aI1ERROu2Ht7cNJPRQ4NKejI_aem_i6snDuwa1Rkos2epFN-Jgg"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check out my short story on Amazon
        </a>
        <br />
        <a
          href="https://www.energheia.org/agartha_anthony-najm-byblos.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          My short story award
        </a>
      </div>
    </>
  );
};

export default ShortStories;
