import AboutMe from '../../Components/AboutMe/AboutMe'
import ShortStories from '../../Components/ShortStories/ShortStories'
import VideoLink from '../../Components/VideoLink/VideoLink'
import './About.scss'

const About = () => {
  return (
    <div className='about'>
      <VideoLink />
      <AboutMe />
      <ShortStories />
    </div>
  )
}

export default About
